


























import { Component, Vue } from "vue-property-decorator";
import __ from "@/shared/helpers/__";
import PageHeader from "@/admin/components/PageHeader.vue";
import FormBase from "@/shared/classes/form/form-base";
import { HttpMethod } from "@/shared/helpers/requests.helper";
import Field, { FieldSizes } from "@/shared/classes/form/field";
import { GlobalActions } from "@/shared/store/global/global.actions";
import { SnackBarTypes } from "@/shared/helpers/snack-bar.helper";
import SearchableField from "@/shared/classes/form/fields/Select/searchable-field";
import HseRoutineTemplate from "@/shared/modules/hse-routine-templates/hse-routine-templates.model";
import { getNodeApiUrlByVersion } from "@/config";
import { CompanyRoutes } from "@/shared/router/company";
import IResponse from "@/shared/interfaces/modules/response.interface";
import IModelResponse from "@/shared/interfaces/modules/model-response.interface";
import baseHttp from "@/shared/http";
import Company from "@/shared/modules/company/company.model";
import { CompanyGetters } from "@/shared/store/company/company.getters";
import Form from "@/shared/components/form/Form.vue";
import EditorField from "@/shared/classes/form/fields/editor-field";
import { validateFields } from "@/shared/helpers/validate";

@Component({
  components: { Form, PageHeader },
  methods: { __ },
})
export default class TemplateRoutineEdit extends Vue {
  form: FormBase | null = null;
  http = baseHttp;
  isEditingCompanyTemplate: boolean = true;
  loaded: boolean = false;

  async created() {
    const setupForm = ({ entry }: { entry: HseRoutineTemplate }) => {
      const valuesToInject: Record<string, any> = {
        companyUuid: this.company.uuid,
        isVisible: true,
      };

      let endpoint = `${getNodeApiUrlByVersion()}/company-hse-routine-templates`;
      if (this.isEditingCompanyTemplate) endpoint += `/${this.$router.currentRoute.params.uuid}`;
      else valuesToInject.hseRiskTemplateUuid = this.$router.currentRoute.params.uuid;

      this.form = new FormBase()
        .setModel(HseRoutineTemplate)
        .setEntry({
          ...entry,
          hseRoutineCategoryUuid: entry.hseRoutineCategoryUuid || entry.companyHseRoutineCategoryUuid,
        })
        .setEndpoint(endpoint)
        .setMethod(this.isEditingCompanyTemplate ? HttpMethod.PATCH : HttpMethod.POST)
        .setTranslatable(true)
        .setUnsetNonFieldsValues(false)
        .setFields([
          new SearchableField()
            .setKey("hseRoutineCategoryUuid")
            .setEntryKey("hseRoutineCategoryUuid")
            .isRequired()
            .setTitle(__("admin.components.routine-templates.form.category"))
            .loadItems({
              endpoint: `${getNodeApiUrlByVersion()}/hse-routines/categories`,
              value: "uuid",
              title: "name",
              perPage: 20,
            })
            .setSize(FieldSizes.half)
            .setOnItemSelect((item: any) => {
              if (this.form) this.form.data.category = item?.meta || null
            }),
          new Field()
            .setKey("name")
            .isRequired()
            .setTranslatable(true)
            .setSize(FieldSizes.half)
            .setTitle(__("admin.components.routine-templates.form.routine-title")),
          new EditorField()
            .setKey("content")
            .setTranslatable(true)
            .setTitle(__("admin.components.routine-templates.create-dialog.form.content"))
            .isRequired(),
        ])
        .setSubmit({
          text: this.isEditingCompanyTemplate
            ? __("general.update")
            : __("general.create"),
        })
        .setInjectValues(valuesToInject)
        .setValidate(true, { onlyRegionLangRequired: true })
        .setShowDefaultValidationError(true)
        .setChangeDataBeforeSubmit(data => {
          const body = { ...data };
          if (data.category?.isCompanyCategory) {
            body.companyHseRoutineCategoryUuid = data.category.uuid;
            body.hseRoutineCategoryUuid = null;
          }
          else {
            body.hseRoutineCategoryUuid = data.category.uuid;
            body.companyHseRoutineCategoryUuid = null;
          }
          delete body.category;

          return body;
        })
        .setOnSuccess(this.onSuccess);
    };

    this.http
      .get(`${getNodeApiUrlByVersion()}/company-hse-routine-templates/${this.$router.currentRoute.params.uuid}?hasTranslations=true`)
      .then((response: IResponse<IModelResponse>) => {
        this.isEditingCompanyTemplate = true;
        setupForm({ entry: response.data });
        this.loaded = true;
      })
      .catch(() => {});

    this.http
      .get(`${getNodeApiUrlByVersion()}/hse-routine-templates/${this.$router.currentRoute.params.uuid}?hasTranslations=true`)
      .then((response: IResponse<IModelResponse>) => {
        this.isEditingCompanyTemplate = false;
        setupForm({ entry: response.data });
        this.loaded = true;
      })
      .catch(() => {});
  }

  goBack(): void {
    this.$router.push({ name: CompanyRoutes.hseRoutinesIndexTabTemplates });
  }

  private onSuccess(data: any): void {
    this.$store.dispatch(GlobalActions.showSnackBar, {
      type: SnackBarTypes.success,
      message: __("company.views.hse-routines.edit.form.on-success"),
    });
    this.goBack();
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany];
  }
}
