import Model from '@/shared/classes/model'
import IHseRoutineCategoryResponse from '@/shared/modules/hse-routine-categories/hse-routine-category-response.interface'

export default class HseRoutineCategory extends Model<IHseRoutineCategoryResponse> {
  name!: string

  transform(data: IHseRoutineCategoryResponse): void {
    super.transform(data)

    this.name = data.name
  }
}
