import Model from '@/shared/classes/model'
import IHseRoutineTemplateResponse from "@/shared/modules/hse-routine-templates/hse-routine-template-response.interface";
import IHseRoutineCategoryResponse from "@/shared/modules/hse-routine-categories/hse-routine-category-response.interface";
import HseRoutineCategory from "@/shared/modules/hse-routine-categories/hse-routine-category.model";

export default class HseRoutineTemplate extends Model<IHseRoutineTemplateResponse> {
  hseRoutineCategoryUuid!: string
  hseRoutineCategory!: IHseRoutineCategoryResponse
  hseRoutineCategoryName!: string
  companyHseRoutineCategoryUuid!: string
  isVisible!: boolean
  name!: string
  content!: string
  isCompanyTemplate!: boolean
  hseRoutineTemplateUuid!: string

  transform(data: IHseRoutineTemplateResponse): void {
    super.transform(data)

    this.hseRoutineCategoryUuid = data.hseRoutineCategoryUuid
    this.companyHseRoutineCategoryUuid = data.companyHseRoutineCategoryUuid
    this.hseRoutineCategoryName = data.hseRoutineCategory?.name || data.companyHseRoutineCategory?.name
    this.isVisible = data.isVisible
    this.name = data.name
    this.content = data.content
    this.isCompanyTemplate = Boolean(data.isCompanyTemplate)
    this.hseRoutineTemplateUuid = data.hseRoutineTemplateUuid

    this.hseRoutineCategory = this.setRelation(HseRoutineCategory, data.hseRoutineCategory)
  }
}
